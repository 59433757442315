<template>
  <div class="col-8 mx-auto justify-content-center">
      Dodaj pracownika
    <form class="add-form bg-light border" @submit.prevent>
      <small>Wybierz kontrahenta</small>
      <v-select
        v-model="contractor"
        :options="contractors"
        placeholder="Wybierz kontrahenta"
      ></v-select>
      <small>Numer seryjny urządzenia</small>
      <input
        v-model="serialNumber"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Numer seryjny urządzenia"
      />
      <small>Ilość czynnika</small>
      <input
        v-model="amountOfRefrigerant"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Ilość czynnika"
      />
      <small>Data montazu</small>
      <input
        v-model="date"
        type="date"
        class="form-control mt-1 mb-1"
        placeholder="Data montazu"
      />
      <small>Uwagi</small>
      <!-- <textarea
        v-model="notice"
        type="text"
        class="form-control mb-1"
        placeholder="Uwagi"
      /> -->
      <vue-editor v-model="notice"></vue-editor>
      <small>Przegląd</small>
      <div class="row mb-3">
        <div class="col-6">
          <div class="form-check">
            <input
                v-model="overview"
              class="form-check-input"
              type="radio"
              name="overview"
              id="halfyear"
              v-bind:value="6"
              checked
            />
            <label class="form-check-label" for="halfyear">
              za 6 miesięcy
            </label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-check">
            <input
               v-model="overview"
              class="form-check-input"
              type="radio"
              name="overview"
              id="year"
              v-bind:value="12"
            />
            <label class="form-check-label" for="year"> za 12 miesięcy </label>
          </div>
        </div>
      </div>
      <button
        style="width: 100%; height: 65px"
        class="btn btn-info btn-sm mt-1"
        @click="addInstallation()"
      >
        Dodaj instalacje
      </button>
    </form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
  data() {
    return {
      contractor: "",
      serialNumber: "",
      amountOfRefrigerant: "",
      notice: "",
      date:"",
      overview:"",
    };
  },
  computed: {
    ...mapState(["userProfile", "contractors"]),
  },
  components: {
    VueEditor
  },
  methods:{
      addInstallation(){
            this.$store.dispatch("createInstallation", {
                contractor:this.contractor,
                serialNumber:this.serialNumber,
                amountOfRefrigerant:this.amountOfRefrigerant,
                date:this.date,
                notice:this.notice,
                overview: this.overview

            })
  }
}
}
</script>